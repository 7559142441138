import { useEffect, useState } from "react";
import styled from "styled-components";
import { getRequest } from "../../../util/request";
import { SHOPIFY_COLLECTIONS_URL } from "../../../common/urls";
import {ReactComponent as UploadIcon} from "../../../../static/tildi-upload-icon.svg";
import LoadingOverlay from "../../../common/LoadingOverlay";
import {ReactComponent as TrashIcon} from "../../../../static/tildi-trash-icon.svg";


const CustomerAdminScreenWrapper = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    .customer-admin-screen-title{
        text-align: center;
    }
    .customer-admin-form-wrapper{
        width: 50%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        // margin: 20px auto;
        .customer-admin-field-wrapper{
            // width: 50%;
            margin: 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        .user-field{
            margin: 0 10px;
            padding: 5px 10px;
        }
        .field-input{
            width: 200px;
            max-width: 200px;
        }
        .customer-create-button{
            width: 150px;
            height: 30px;
            margin: 30px auto;
        }
        select.field-input{
            width: 225px;
            max-width: 225px;
        }
    }
    .existing-customers-section{
        padding: 20px;
        background-color: #ffffff6e;
        .existing-customers-section-header{
            text-align: center;
        }
        .existing-customers-list{
            margin: 20px auto;
            padding: 20px;
            width: 90%;
            table{
                text-align: left;
                width: 100%;
                .customers-row:nth-child(even){
                    background-color: white;
                }
                .customers-row:nth-child(odd){
                    background-color: lightgrey;
                }
                .table-cell{
                    padding: 5px;
                    height: 10px;
                    &.expand-invoices-cell{
                        width: 10px;
                        &.disabled{
                            div{
                                display: none;
                            }
                        }
                        .delete-invoice-icon{
                            cursor: pointer;
                            height: 15px;
                            path{
                                fill: red;
                            }
                        }
                    }
                    &.upload-icon-cell{
                        width: 20px;
                        .custom-file-upload{
                            .invoice-upload-input{
                                display: none;
                            }
                        }
                        svg{
                            height: 15px;
                        }
                    }
                    &.name-cell{
                        max-width: 40px;
                        &.file-name-cell{
                            text-align: center;
                        }
                    }
                    &.warehouse-cell{
                        max-width: 40px;
                    }
                }
            }
        }
    }
    .loading-screen{
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;

    }
`

const ToBeDeletedDialogWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #8080807a;
    display: flex;
    align-items: center;
    justify-content: center;
    .delete-confirmation-dialog{
        width: 500px;
        height: 200px;
        background: white;
        padding: 20px 50px;
        button{
            margin: 20px 10px;
            padding: 5px;
        }
    }
`;

const CustomerAdminScreen = (props) => {
    const [customerName, setCustomerName] = useState("");
    const [customerIconUrl, setCustomerIconUrl] = useState("");
    const [shopSellerName, setShopSellerName] = useState("");
    const [dataLoading, setDataLoading] = useState(false);
    const [expandedCustomer, setExpandedCustomer] = useState(false);
    const [toBeDeleted, setToBeDeleted] = useState(false);
    const customers = props.customers;
    const resetForm = () => {
        setCustomerName("");
        setCustomerIconUrl("");
        setShopSellerName("");
    }
    const handleFailure = () => {
        setDataLoading(false);
    }
    const handleUploadFile = (file, customerId) => {
        console.log(file);
        var reader = new FileReader();
        reader.addEventListener(
            "load",
            () => {
                var fileBinary = reader.result;
                const invoiceDetails = {
                    customer: customerId,
                    file: fileBinary.substr(fileBinary.indexOf(',') + 1),
                    filename: file.name
                }
                setDataLoading(true);
                props.onUploadInvoice(invoiceDetails, (response) => {console.log(response); setDataLoading(false)},handleFailure);
            }  
        );
        if(file){
            reader.readAsDataURL(file)
        }
    }
    return (
        <CustomerAdminScreenWrapper>
            <div className="create-customer-section">
                <h2 className="customer-admin-screen-title">Create New Customer</h2>
                <form 
                    className="customer-admin-form-wrapper" 
                    onSubmit={(e)=>{
                        var createCustomerBody = {
                            name: customerName,
                            icon: customerIconUrl,
                            shop_seller_name: shopSellerName,
                        }
                        e.preventDefault();
                        props.onCreateNew(createCustomerBody, ()=>{resetForm()})
                    }}
                >
                    <div className="customer-admin-field-wrapper">
                        <label className="user-field field-label" for="user-email">Customer Name</label>
                        <input 
                            className="user-field field-input" 
                            id="customer-name" 
                            type="text"
                            value={customerName}
                            onChange={(e)=>{setCustomerName(e.target.value)}}
                        />
                    </div>
                    <div className="customer-admin-field-wrapper">
                        <label className="user-field field-label" for="user-first-name">Customer Icon Url</label>
                        <input 
                            className="user-field field-input" 
                            id="customer-icon-url" 
                            type="url"
                            value={customerIconUrl}
                            onChange={(e)=>{setCustomerIconUrl(e.target.value)}}
                        />
                    </div>
                    <div className="customer-admin-field-wrapper">
                        <label className="user-field field-label" for="user-first-name">Shop Seller Name (if different from Name)</label>
                        <input 
                            className="user-field field-input" 
                            id="customer-icon-url" 
                            type="text"
                            value={shopSellerName}
                            onChange={(e)=>{setShopSellerName(e.target.value)}}
                        />
                    </div>
                    <button className="customer-create-button" type="submit">Create Customer</button>
                </form>
            </div>
            <div className="existing-customers-section">
                <h2 className="existing-customers-section-header">Customers</h2>
                <div className="existing-customers-list">
                    <table>
                        <tr className="customers-header-row">
                            <th className="table-cell expand-invoices-cell"></th>
                            <th className="table-cell name-cell">Customer Name</th>
                            <th className="table-cell upload-icon-cell">Invoice Upload</th>
                        </tr>
                        {customers ? customers.map((customerObject)=>{
                            return (
                                <>
                                    <tr className="customers-row">
                                        <td className={`table-cell expand-invoices-cell ${customerObject.invoices.length > 0 ? "":'disabled'}`}><div onClick={() => {setExpandedCustomer(customerObject.id)}}>&gt;</div></td>
                                        <td className="table-cell name-cell">{customerObject.name}</td>
                                        <td className="table-cell upload-icon-cell">
                                            <label htmlFor={`invoice-upload-${customerObject.id}`} className="custom-file-upload">
                                                <UploadIcon/>
                                                <input 
                                                    className="invoice-upload-input"
                                                    id={`invoice-upload-${customerObject.id}`}
                                                    type="file" 
                                                    onChange={
                                                        (e) => {
                                                            if(e.target.files[0]){
                                                                console.log(customerObject);
                                                                handleUploadFile(e.target.files[0], customerObject.id);
                                                            };
                                                        }
                                                    }
                                                />
                                            </label>
                                        </td>
                                    </tr>
                                    {
                                        expandedCustomer === customerObject.id ? 
                                        (customerObject.invoices.map((invoiceObject)=>{

                                            return (
                                                <tr className="customers-row">
                                                    <td className="table-cell expand-invoices-cell">
                                                        <TrashIcon className="delete-invoice-icon" 
                                                            onClick={() => {
                                                                setToBeDeleted(invoiceObject);
                                                            }}
                                                        />
                                                    </td>
                                                    <td className="table-cell name-cell file-name-cell" colSpan="3" >{invoiceObject.filename}</td>
                                                </tr>
                                            )
                                        }))
                                        :
                                        ""
                                    }
                                </>
                            );
                        }) : ""}
                    </table>
                </div>
            </div>
            {
                toBeDeleted ? 
                <ToBeDeletedDialogWrapper>
                    <div className="delete-confirmation-dialog">
                        <h2>Are you sure you wish to delete the invoice: {toBeDeleted.filename}?</h2>
                        <button onClick={()=> {
                            setDataLoading(true);
                            props.onDeleteInvoice(
                                toBeDeleted.id,
                                {id: toBeDeleted.id, pk: toBeDeleted.id},
                                (response) => {
                                    setDataLoading(false);
                                    setToBeDeleted(false);
                                },
                                handleFailure
                            )
                        }}
                        >Ok</button>
                        <button onClick={()=> {setToBeDeleted(false)}}>Cancel</button>
                    </div>
                </ToBeDeletedDialogWrapper>
                :
                ""
            }
            <LoadingOverlay isLoading={dataLoading}/>
        </CustomerAdminScreenWrapper>
    )
}

export default CustomerAdminScreen;